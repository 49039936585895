import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getAllGroups from '../../api/private/users_management/gets/getGroups';

// First, create the thunk
export const fetchGroupList = createAsyncThunk(
  'groupList',
  async () => {
    try {
      const { data } = await getAllGroups();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);

const groupListSlice = createSlice({
  initialState: {
    groupList: {},
  },
  name: 'groups',
  reducers: {
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupList.fulfilled, (state, action) => {
      state.groupList = action.payload;
    });
  },
});

export const { setGroupList } = groupListSlice.actions;

export default groupListSlice.reducer;
