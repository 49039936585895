import postRequest from '../../../postRequest';

/**
 * Adds user into PRISM db.
 *
 * @param {string} groupName - JDUK Outdoors
 * @param {number} groupAdminName - ashwini.sawarkar@jdplc.com
 * @param {Array[number]} siteIds - [0, 1]
 * @param {number} status - 0
 * @returns {object} { Success: true, Error: null }
 */
function postAddGroup(groupName, siteIds, status) {
  const endpoint = '/users-management/add-group';

  const body = {
    groupName,
    siteIds,
    status,
  };
  return new Promise((resolve, reject) => {
    postRequest(endpoint, body, 'Add Group')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default postAddGroup;
