import getRequest from '../../../getRequest';

/**
 * Get all users from PRISM db.
 *
 * @returns {object}
 */
const getAllUsers = (search) => {
  const endpoint = `/users-management/get-all-users?search=${search === undefined ? '' : search}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get All Users')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export default getAllUsers;
