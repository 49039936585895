import { createSlice } from '@reduxjs/toolkit';

import INITIAL_STATE from './initial_state';

const nikeWizardSlice = createSlice({
  initialState: INITIAL_STATE,
  name: 'nikeWizard',
  reducers: {
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    increaseActivePage: (state) => {
      state.activePage += 1;
    },
    decreaseActivePage: (state) => {
      state.activePage -= 1;
    },
    // FindProduct
    setProductId: (state, action) => {
      state.productId = action.payload;
    },
    setProductPlu: (state, action) => {
      state.productPlu = action.payload;
    },
    // WhereToSell
    setProductsSites: (state, action) => {
      state.sites = action.payload;
    },
    setProductSitesFascia: (state, action) => {
      state.sites = state.sites.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            fascia: action.payload.fascia,
          };
        }
        return obj;
      });
    },
    setProductSitesCountry: (state, action) => {
      state.sites = state.sites.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            countries: action.payload.countries,
          };
        }
        return obj;
      });
    },
    setInitialProductSites: (state, action) => {
      const fascias = Object.entries(action.payload);
      state.sites = fascias.map(([key, values], index) => ({
        id: index,
        fascia: key,
        countries: values,
      }));
    },
    addSiteRow: (state) => {
      const defaultSiteObject = {
        id: state.sites.length + 1,
        fascia: '',
        countries: [],
      };
      state.sites = state.sites.push(defaultSiteObject);
    },
    removeSiteRow: (state, action) => {
      state.sites = state.sites.filter((obj) => obj.id !== action.payload.id);
    },
    // ProductData
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setProductDataActiveStep: (state, action) => {
      state.productData.activeStep = action.payload;
    },
    setProductDataBrandObject: (state, action) => {
      state.productData.brand = action.payload;
    },
    setProductDataBrandName: (state, action) => {
      state.productData.brand.brand_name = action.payload;
    },
    setProductDataSubBrand: (state, action) => {
      state.productData.brand.subbrand = action.payload;
    },
    setProductDataProductNameObject: (state, action) => {
      state.productData.product_name = action.payload;
    },
    setProductDataProductName: (state, action) => {
      state.productData.product_name.name = action.payload;
    },
    setProductDataProductNameTranslations: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.productData.product_name.translations = action.payload;
      }
      if (typeof action.payload !== 'object') {
        const existingTranslation = state.productData.product_name.translations.find(
          (translation) => translation.country_code === action.payload.country_code,
        );
        if (existingTranslation == null) {
          state.productData.product_name.translations.push(action.payload);
        }
      }
    },
    setProductDataDescriptionObject: (state, action) => {
      state.productData.product_description = action.payload;
    },
    setProductDataDescription: (state, action) => {
      state.productData.product_description.description = action.payload;
    },
    setProductDataDescriptionTranslations: (state, action) => {
      const existingTranslation = state.productData.product_description.translations.find(
        (translation) => translation.country_code === action.payload.country_code,
      );
      if (existingTranslation == null) {
        state.productData.product_description.translations.push(action.payload);
      }
    },
    setProductDataColour: (state, action) => {
      state.productData.colours = action.payload;
    },
    setProductDataFabricObject: (state, action) => {
      state.productData.fabric = action.payload;
    },
    setProductDataFabricType: (state, action) => {
      state.productData.fabric.fabric_type = action.payload;
    },
    setProductDataFabricTypeTranslations: (state, action) => {
      const existingTranslation = state.productData.fabric.translations.find(
        (translation) => translation.country_code === action.payload.country_code,
      );
      if (existingTranslation == null) {
        state.productData.fabric.translations.push(action.payload);
      }
    },
    setProductDataInitialPimCategories: (state, action) => {
      state.productData.initial_pim_categories = action.payload;
    },
    setProductDataCategories: (state, action) => {
      state.productData.categories = action.payload;
    },
    setProductDataVat: (state, action) => {
      state.productData.vat = action.payload;
    },
    setProductDataOtherDataObject: (state, action) => {
      state.productData.other_attributes = action.payload;
    },
    setProductDataOtherDataSeason: (state, action) => {
      state.productData.other_attributes.season = action.payload;
    },
    setProductDataOtherDataSeasonYear: (state, action) => {
      state.productData.other_attributes.season_year = action.payload;
    },
    setProductDataOtherDataConnectedCustomers: (state, action) => {
      state.productData.other_attributes.connected_customers = action.payload;
    },
    setProductDataOtherDataLicensed: (state, action) => {
      state.productData.other_attributes.licensed = action.payload;
    },
    setProductDataOtherDataExclusiveDateFrom: (state, action) => {
      state.productData.other_attributes.from = action.payload;
    },
    setProductDataOtherDataExclusiveDateTo: (state, action) => {
      state.productData.other_attributes.to = action.payload;
    },
    setEditedValues: (state, action) => {
      if (!state.editedValues.includes(action.payload)) {
        state.editedValues.push(action.payload);
      }
    },
    // Summary
    setMissingVatTableValues: (state, action) => {
      state.missingValues = action.payload;
    },
    // // EnableFulfilment
    setEnableFulfilment: (state, action) => {
      state.enableFulfilment = action.payload;
    },
    resetState: () => INITIAL_STATE,
  },
});

export const {
  setActivePage,
  increaseActivePage,
  decreaseActivePage,
  setProductId,
  setProductPlu,
  setProductsSites,
  setProductSitesFascia,
  setProductSitesCountry,
  setInitialProductSites,
  addSiteRow,
  removeSiteRow,
  setProductData,
  setProductDataActiveStep,
  setProductDataBrandObject,
  setProductDataBrandName,
  setProductDataSubBrand,
  setProductDataProductNameObject,
  setProductDataProductName,
  setProductDataProductNameTranslations,
  setProductDataDescriptionObject,
  setProductDataOtherDataLicensed,
  setProductDataDescription,
  setProductDataDescriptionTranslations,
  setProductDataColour,
  setProductDataFabricObject,
  setProductDataFabricType,
  setProductDataFabricTypeTranslations,
  setProductDataInitialPimCategories,
  setProductDataCategories,
  setProductDataVat,
  setProductDataOtherDataObject,
  setProductDataOtherDataSeason,
  setProductDataOtherDataSeasonYear,
  setProductDataOtherDataConnectedCustomers,
  setProductDataOtherDataExclusiveDateFrom,
  setProductDataOtherDataExclusiveDateTo,
  setEditedValues,
  setMissingVatTableValues,
  setEnableFulfilment,
  resetState,
} = nikeWizardSlice.actions;

export default nikeWizardSlice.reducer;
