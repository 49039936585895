import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import postAddProductLog from '../../api/intergration/pim/products/dropshipment/posts/postAddProductLog';
import getSites from '../../api/intergration/pim/sites/gets/getSites';
import postProducts from '../../api/intergration/shogun/products/posts/postProducts';
import WizardPages from '../../dummy_data/WizardPages';
import { decreaseActivePage, increaseActivePage, setActivePage } from '../../redux/wizard/nike/nike_wizard';
import theme from '../../theme';
import ConfirmModal from './modals/ConfirmModal';
import NextButtonDisabled from './next_button_disabled';
import Pages from './Pages';
import saveProductData from './save_product_data/saveProductData';

// Top level of the Wizard component.
// Contains Pages
// Previous, Next, Save, Submit and Finish Buttons.
function Wizard() {
  const dispatchToRedux = useDispatch();
  const matches = useMediaQuery('(min-width:600px)');
  const pim_sites = useSelector((state) => state.pim_sites);
  const userEmail = useSelector((state) => state.user.loginResponse.Username);
  const nike_wizard = useSelector((state) => state.nike_wizard);

  const { activePage } = nike_wizard;

  // Type will change what pages are rendered from the API
  // But because there isnt an API yet, we will just use the dummy data
  // And we will console log the type
  const steps = WizardPages;
  // type from parent
  // console.warn('Type', type);

  // const [activeStep, setActiveStep] = React.useState(1);
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState({
    disabled: true,
    reasons: [],
  });
  const [currentPage, setCurrentPage] = React.useState(null);
  const [resetState, setResetState] = React.useState(false);
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalType, setModalType] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [shogunResponse, setShogunResponse] = React.useState(false);
  const [pimErrors, setPimErrors] = React.useState(new Set());

  const handleNext = () => dispatchToRedux(increaseActivePage());
  const handleBack = () => dispatchToRedux(decreaseActivePage());

  const handleSave = () => {
    saveProductData(userEmail, nike_wizard, pim_sites)
      .then(() => {
        setLoading(false);
        dispatchToRedux(increaseActivePage());
        setOpenModal(false);
        setPimErrors(new Set());
      })
      .catch((err) => {
        if (err === true) console.error('Cancelling duplicate request.');
        setPimErrors(err);
        setLoading(false);
      });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const sitesRes = await getSites(1, 56)
      .catch((err) => console.error(err));
    const sitesData = sitesRes.data.Data;
    const sitesCountries = nike_wizard.sites.map((site) => site.countries);
    const correctCountryAndFascia = sitesCountries[0].map((countryCode) => sitesData
      .filter((site) => site.CountryCode === countryCode && site.Fascia === 'JD'));
    const flattened = correctCountryAndFascia.flat();
    const siteCodes = flattened.map((z) => z.SiteCode);
    postProducts(nike_wizard.productId, nike_wizard.sites[0].fascia, siteCodes)
      .then((response) => {
        // This means the request has been canceled by the user.
        if (response === undefined) {
          setLoading(false);
        } else if (response?.data.Response === 0) {
          // Success
          toast.success('Successfully Submitted to Shogun');
          const shogunEmptySellingPrices = nike_wizard.sellingPrice.map((price) => {
            const onlyShogunAndHasPrice = price.data.filter((obj) => obj.Name === 'Shogun' && (obj.SellingPrice <= 0 || obj.SellingPrice == null));
            return onlyShogunAndHasPrice;
          });
          if (shogunEmptySellingPrices.some((price) => price.length > 0)) {
            // if response
            // from shogun is successful
            // && product has NO shogun price
            // Then go back to step 1
            postAddProductLog(
              nike_wizard.productId,
              nike_wizard.sites[0].fascia,
              `[SUCCESS]: User ${userEmail} has submitted ProductId: ${nike_wizard.productId} - SiteCode: ${nike_wizard.sites[0].fascia} to Shogun. ProductId: ${nike_wizard.productId} has no price so going back to step one.`,
            );
            setLoading(false);
            dispatchToRedux(setActivePage(1));
            setEnableSubmit(false);
            setOpenModal(false);
            setResetState(true);
          } else {
            // if response
            // from shogun is successful
            // && product has shogun prices selling price for at least one of its variants
            // Then go to next step
            postAddProductLog(
              nike_wizard.productId,
              nike_wizard.sites[0].fascia,
              `[SUCCESS]: User ${userEmail} has submitted ProductId: ${nike_wizard.productId} - SiteCode: ${nike_wizard.sites[0].fascia} to Shogun`,
            );
            setShogunResponse(response?.data);
            setLoading(false);
            dispatchToRedux(setActivePage(1));
            // setEnableSubmit(true);
            setOpenModal(false);
          }
        } else {
          // Error
          // If the product has failed to proccess into shogun
          // Then toast error message && go back to step 1
          postAddProductLog(
            nike_wizard.productId,
            nike_wizard.sites[0].fascia,
            `[ERROR]: User ${userEmail} has submitted ProductId: ${nike_wizard.productId} - SiteCode: ${nike_wizard.sites[0].fascia} to Shogun. ResponseCode: ${response?.data.Response} ErrorMessage: ${response?.data.ErrorMessage}`,
          );
          toast(`Error Submitting to Shogun: ${response?.data.Response} - ${response?.data.ErrorMessage}`, {
            type: 'error',
            autoClose: 30000,
          });
          setLoading(false);
          dispatchToRedux(setActivePage(1));
          setEnableSubmit(false);
          setOpenModal(false);
          setResetState(true);
        }
      })
      .catch((err) => {
        // Error
        // If the product has failed to proccess into shogun
        // Then toast error message && go back to step 1
        postAddProductLog(
          nike_wizard.productId,
          nike_wizard.sites[0].fascia,
          `[ERROR]: User ${userEmail} has submitted ProductId: ${nike_wizard.productId} - SiteCode: ${nike_wizard.sites[0].fascia} to Shogun. Error: ${err}`,
        );
        console.error(err);
        setLoading(false);
        dispatchToRedux(setActivePage(1));
        setEnableSubmit(false);
        setOpenModal(false);
        setResetState(true);
      });
    // setLoading(false);
    // setActiveStep(activeStep + 1);
    // setEnableSubmit(false);
    // setOpenModal(false);
  };

  // const handleFinish = () => {
  //   setResetState(true);
  //   dispatchToRedux(setActivePage(1));
  // };

  React.useEffect(() => () => {
    dispatchToRedux(setActivePage(1));
  }, []);

  React.useEffect(() => {
    setEnableSubmit(false);
    setCurrentPage(WizardPages.filter((page) => page.order === activePage)[0]);
  }, [activePage]);

  React.useEffect(() => {
    const obj = NextButtonDisabled(nike_wizard, currentPage?.pageName);
    setNextButtonDisabled(obj);
    setResetState(false);
  }, [currentPage]);

  React.useEffect(() => {
    const isDisabled = NextButtonDisabled(nike_wizard, currentPage?.pageName);
    setNextButtonDisabled(isDisabled);
  }, [nike_wizard]);

  const buttons = (buttonType) => {
    // if (activePage === steps.length + 1) {
    //   return (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       size={matches ? 'large' : 'medium'}
    //       onClick={handleFinish}
    //     // disabled={nextButtonDisabled.disabled}
    //     >
    //       Finish
    //     </Button>
    //   );
    // }
    if (buttonType === true) {
      return (
        <Button
          variant="contained"
          color="success"
          size={matches ? 'large' : 'medium'}
          onClick={() => {
            setModalType('submit');
            setOpenModal(true);
          }}
          disabled={nike_wizard.missingValues}
        >
          {nike_wizard.missingValues ? (
            <Typography variant="body2" className="font-gothammedium">
              Missing Values.
              <br />
              See VAT Table
            </Typography>
          ) : 'Submit'}
        </Button>
      );
    }
    if (buttonType === 'save') {
      return (
        <Tooltip
          title={(
            <ul>
              {Array.from(nextButtonDisabled.reasons)?.map((tooltip) => (
                <li key={tooltip}>{tooltip}</li>
              ))}
            </ul>
          )}
          arrow
          placement="top"
          disableHoverListener={!nextButtonDisabled.disabled}
        >
          <div>
            <Button
              variant="contained"
              color="success"
              size={matches ? 'large' : 'medium'}
              onClick={() => {
                setModalType('save');
                setOpenModal(true);
              }}
              disabled={nextButtonDisabled.disabled}
            >
              Save
            </Button>
          </div>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={(
          <ul>
            {Array.from(nextButtonDisabled.reasons)?.map((tooltip) => (
              <li key={tooltip}>{tooltip}</li>
            ))}
          </ul>
        )}
        arrow
        placement="top"
        disableHoverListener={!nextButtonDisabled.disabled}
      >
        <div>
          <Button
            variant="contained"
            color="info"
            size={matches ? 'large' : 'medium'}
            onClick={handleNext}
            disabled={nextButtonDisabled.disabled}
          >
            Next
          </Button>
        </div>
      </Tooltip>
    );
  };

  return (
    <Grid
      container
      direction={matches ? 'row' : 'column-reverse'}
      className="rounded-xl sm:shadow-lg"
    >
      <ConfirmModal
        openModal={openModal}
        loading={loading}
        type={modalType}
        errors={pimErrors}
        submitClicked={() => {
          if (modalType === 'save') {
            handleSave();
          }
          if (modalType === 'submit') {
            handleSubmit();
          }
        }}
        cancelClicked={() => setOpenModal(false)}
      />
      {/* Content */}
      <Grid item xs={12} md={9} container alignItems="center">
        <Box
          className="relative w-full h-full bg-white border sm:rounded-tl-xl rounded-bl-xl rounded-br-xl sm:rounded-br-none"
        >
          <Tooltip title={`PLU: ${nike_wizard.productPlu}`} arrow>
            <div className={`absolute right-2 top-2 md:top-5 md:right-10 ${nike_wizard.productPlu && activePage !== 1 ? '' : 'hidden'} z-20 bg-white p-2 py-1 rounded-md`}>
              <legend className="text-xs">ProductId:</legend>
              <Typography variant="body1">
                {nike_wizard.productId}
              </Typography>
            </div>
          </Tooltip>
          <Box
            sx={{
              overflowY: 'scroll',
              height: ['70vh', '75vh'],
              position: 'relative',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Pages
              pageName={currentPage?.pageName}
              shogunResponse={shogunResponse}
              reset={resetState}
              nextButtonDisabledReasons={nextButtonDisabled.reasons}
              forceNext={() => handleNext()}
              enableSubmit={() => setEnableSubmit(true)}
              save={() => setEnableSubmit('save')}
            />
          </Box>

          <Box className="flex items-center justify-end h-20 border-t rounded-bl-xl">
            <Stack direction="row" spacing={4} marginRight={matches ? 8 : 4}>
              <Button
                variant="text"
                size={matches ? 'large' : 'medium'}
                onClick={handleBack}
                disabled={activePage === 1}
              >
                Previous
              </Button>
              {buttons(enableSubmit)}
            </Stack>
          </Box>
        </Box>
      </Grid>

      {/* Steps */}
      <Grid item xs={12} md={3}>
        <Box
          className="
            sm:rounded-tr-xl
            sm:rounded-br-xl
            sm:flex-col
            bg-tertiary-600
            h-full
            w-full
            flex
            flex-nowrap
            max-h-[75px]
            sm:max-h-full
            overflow-y-hidden
            sm:overflow-y-visible
            sm:max-w-full
          "
        >
          <Box
            className="flex-col h-full transition-transform sm:flex justify-evenly sm:-ml-10"
            sx={{ transform: !matches && `translate(0, -${75 * (activePage - 1)}px)` }}
          >
            {steps.map((step, index) => (
              <Box
                key={step.id}
                className="flex items-center w-full p-4 sm:h-[100px] h-[75px] sm:max-h-full"
                sx={{ cursor: (index + 1) <= activePage ? 'pointer' : '' }}
                onClick={() => {
                  if ((index + 1) <= activePage) {
                    dispatchToRedux(setActivePage(index + 1));
                  }
                }}
              >
                {/* Step Number */}
                <Box className="z-50">
                  <Paper
                    elevation={2}
                    key={step.title}
                    className="flex items-center justify-center w-12 h-12 rounded-full"
                    sx={{
                      backgroundColor: (index + 1) <= activePage
                        ? theme.palette.secondary[500] : '#fff',
                    }}
                  >
                    <Typography
                      variant="h6"
                      className="text-center font-gothambold"
                      sx={{
                        color: (index + 1) <= activePage ? '#ffff' : '#d2d2d2',
                      }}
                    >
                      {(index + 1) < activePage ? <DoneIcon /> : (index + 1)}
                    </Typography>
                  </Paper>
                </Box>
                {/* Title and Description */}
                <Box
                  className="pl-4 sm:pr-6"
                  sx={{ color: (index + 1) <= activePage ? '#fff' : '#888' }}
                >
                  <Typography
                    className="font-gothambold sm:text-lg text-md"
                  >
                    {step.title}
                  </Typography>
                  <Typography className="hidden text-xs sm:text-sm sm:block">
                    {step.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <div style={{ height: '90px' }} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Wizard;
