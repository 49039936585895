const INITIAL_STATE = {
  activePage: 1,
  productId: '',
  productPlu: '',
  sites: [
    {
      id: 1,
      fascia: '',
      countries: [],
    },
  ],
  productData: {
    activeStep: 0,
    brand: {
      brand_name: {
        id: 0,
        name: '',
      },
      subbrand: '',
      shogun_supplier_number: '15',
    },
    product_name: {
      name: '',
      translations: [
        {
          country_code: '',
          translation: '',
        },
      ],
    },
    product_description: {
      description: '',
      translations: [
        {
          country_code: '',
          translation: '',
        },
      ],
    },
    colours: {
      primary_colour: '',
      secondary_colour: '',
      shogun_colour_code: '999',
    },
    fabric: {
      fabric_type: '',
      translations: [
        {
          country_code: '',
          translation: '',
        },
      ],
    },
    categories: {
      pim_category: [], // dropdown of all categories
      shogun_category: '', // helper text 2 char
      shogun_subcategory: '00',
      shogun_buyer_number: 0,
      shogun_division_number: '1',
    },
    vat: [
      {
        sku: '',
        sizes: [
          {
            size: '',
            country_code: '',
          },
        ],
        vat_code: [
          {
            is_vatable: false,
            country_code: '',
          },
        ],
      },
    ],
    other_attributes: {
      season_year: '',
      season: '',
      age_restricted: 'no',
      licensed: 0,
      connected_customers: 0,
      from: '',
      to: '',
    },
  },
  missingValues: false,
  sellingPrice: [
    {
      id: 1,
      country: '',
      data: [
        {
          anatwineSkuId: '',
          shogunSkuId: '',
          size: '',
          sellingPrice: '',
        },
      ],
    },
  ],
  // enableFulfilment: [
  //   [
  //     {
  //       id: 1,
  //       site: '',
  //       anatwinePlu: '',
  //       anatwineSkuId: '',
  //       fulfilmentEnabled: false,
  //     },
  //   ],
  // ],
  translations: [
    {
      language: '',
      product_name: '',
    },
  ],
  editedValues: [],
};

export default INITIAL_STATE;
