import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  DataGrid, GridToolbar,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import getAllUserGroup from '../../api/private/users_management/gets/getAllUserGroup';
import Modal from '../../components/Modal';
import NewGroupModal from './modals/NewGroup';

function UserGroupList({
  groupCount,
}) {
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedGroup, setSelectedGroup] = React.useState({});
  const [selectedGroupId, setSelectedGroupId] = React.useState({});
  const [buttonAnchor, setButtonAnchor] = React.useState(null);

  const getAllUserGroups = async () => {
    setLoadingTable(true);
    try {
      const userGroupList = await getAllUserGroup();
      return userGroupList;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoadingTable(false);
    return true;
  };

  const handleMenuOpen = (event, id) => {
    event.stopPropagation();
    setSelectedGroupId(id);
    setButtonAnchor(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setSelectedGroupId(null);
    setButtonAnchor(null);
    setSelectedGroup(null);
  };

  const columns = [
    {
      field: 'GroupName',
      headerName: 'Group Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'GroupAdmin',
      headerName: 'Group Admin',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'UserCount',
      headerName: 'User count',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'InsertedDate',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
          .replace(',', '');
      },
    },
    {
      field: 'UpdatedDate',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = params.value === '0001-01-01T00:00:00' ? null : new Date(params.value);
        return date === null ? 'No Update' : date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
          .replace(',', '');
      },
    },

    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (group) => (
        <span>
          {group.row.Status === 2 ? 'Active' : 'Disabled'}
        </span>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (group) => (
        <>
          <Button
            size="small"
            onClick={(event) => {
              setSelectedGroup(group);
              handleMenuOpen(event, group.Id);
            }}
          >
            <MoreVertSharpIcon />
          </Button>
          <Menu
            id={`menu-${group.Id}`}
            anchorEl={buttonAnchor}
            open={selectedGroupId === group.Id}
            onClose={(event) => handleMenuClose(event)}
          >
            <MenuItem
              onClick={() => {
                setSelectedGroup(group);
                setOpenModal(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem onClick={() => {
              setSelectedGroup(group);
            }}
            >
              Disable
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const getTableDetails = async () => {
      setLoadingTable(true);
      try {
        const userGroupList = await getAllUserGroups();
        setRows(userGroupList.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingTable(false);
      }
    };
    getTableDetails();
  }, [groupCount]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error);
  }, []);

  return (
    <div className="width: '100%'; margin-right: -20px;">
      <Box
        component="div"
        sx={{
          height: 'calc(100vh - 220px)',
          width: 'calc(100vw - 126px)',
          mt: -1,
          ml: 3.5,
          mr: -30,
        }}
      >
        {open && (
          <NewGroupModal
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
          />
        )}
        <DataGrid
          rows={rows}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          getRowId={(r) => r.Id}
          slots={{
            toolbar: GridToolbar,
          }}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          disableSelectionOnClick
          className="bg-white"
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[20, 30, 40]}
          loading={loadingTable}
        />
      </Box>
      {selectedGroup && (
        <Modal
          title={`${selectedGroup.GroupName} `}
          open={openModal}
          onClose={handleModalClose}
          buttonLabel="Confirm"
          buttonOnClick={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
}

export default UserGroupList;
