import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getAllUsers from '../../api/private/users_management/gets/getAllUsers';

// First, create the thunk
export const fetchUserList = createAsyncThunk(
  'userList',
  async () => {
    try {
      const { data } = await getAllUsers();
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
);

const userListSlice = createSlice({
  initialState: {
    userList: {},
  },
  name: 'users',
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserList.fulfilled, (state, action) => {
      state.userList = action.payload;
    });
  },
});

export const { setUserList } = userListSlice.actions;

export default userListSlice.reducer;
