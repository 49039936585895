import { configureStore } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import bulkOperationsReducer from './bulk_operations/BulkOperations';
import columnsReducer from './columns/column';
import fasciasReducer from './fascias/Fascias';
// import localeReducer from './locale/Locale';
import languageReducer from './language/Language';
import queriesReducer from './queries/Queries';
import sitePermissionsReducer from './sites/sitePermissions';
import sitesReducer from './sites/sites';
import groupListReducer from './user/GroupListRedux';
import userReducer from './user/User';
import userListReducer from './user/UserListRedux';
import nikeWizardReducer from './wizard/nike/nike_wizard';
import selectedProductDataReducer from './wizard/nike/selected_product_data';

const rootReducer = combineReducers({
  user: userReducer,
  // locale: localeReducer,
  language: languageReducer,
  queries: queriesReducer,
  fascias: fasciasReducer,
  pim_sites: sitesReducer,
  nike_wizard: nikeWizardReducer,
  selected_product_data: selectedProductDataReducer,
  bulk_operations: bulkOperationsReducer,
  site_permissions: sitePermissionsReducer,
  columns: columnsReducer,
  users: userListReducer,
  groups: groupListReducer,
});

const persistConfig = {
  key: 'reduxState',
  storage: localForage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
  reducer: persistedReducer,
});

export { store };
